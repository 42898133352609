import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueProgressBar from 'vue-progressbar'
import "./util/http"
import plugins from './plugins' // plugins
import Vant from 'vant';
import 'vant/lib/index.css';
import VueClipboard from 'vue-clipboard2'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(plugins)
Vue.use(VueClipboard)
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

Vue.use(VueProgressBar, {
    color: '#2A7EFB',
    failedColor: 'red',
    height: '2px'
})
