<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return {
    }
  },
  mounted () {
  },
  methods: {
  }

}
</script>
<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  }
  #app {
    width: 100%;
    height: 100%;
  }
  html,body{
    width: 100%;
    height: 100%;

  }
  /** 移动端展示 **/
@media screen and (max-width: 500px) {
    .el-picker-panel__sidebar {
      width: 100%;
    }
    .el-picker-panel {
      width: 350px!important;
    }
    .el-picker-panel__content {
      width: 100%;
    }
    .el-picker-panel__body{
      margin-left: 0!important;
      display: flex;
      flex-direction: column;
      min-width: auto!important;
    }
    .el-picker-panel__sidebar {
      position: relative;
    }
    .el-picker-panel__body-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .el-date-range-picker__content {
      width: 95% !important;
    }
  }

</style>
