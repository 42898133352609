import axios from "axios";
import Vue from 'vue'
import router from '../router'

const BASE_URL = process.env.VUE_APP_BASE_API
let config = {
    baseURL: BASE_URL.indexOf("http") > -1 ? BASE_URL : window.location.protocol+BASE_URL, // 线下测试
    timeout: 60 * 1000 // Timeout
    // withCredentials: true, // Check cross-site Access-Control
}
const _axios = axios.create(config)
_axios.interceptors.request.use(config => {
    // 若果请求url中不包含禁止进度条的接口，那么出现进度条
    Vue.prototype.$Progress.start()

    if (localStorage.getItem('token') && config.url.indexOf('login')) {
        config.headers.token = localStorage.getItem('token')
    }
    return config
});
_axios.interceptors.response.use((response) => {
        Vue.prototype.$Progress.finish();
        if (response.data.code === 200) {
            return Promise.resolve(response.data)
        } else if (response.data.code === 401) {
            Vue.prototype.$modal.msgError("用户无权限")
        }
        return Promise.reject(response.data)
    },
    error => {
        Vue.prototype.$Progress.fail();
        if (error.response?.status === 403) {
            // 判断是否是手机
            // 判断url中是否有admin字段

            console.log(router.currentRoute)
            if (router.currentRoute.name === 'loginMobile' || router.currentRoute.name === 'login') {
                return;
            }
            if (window.location.pathname.indexOf('admin') === -1) {
                router.push({
                    path: '/loginMobile',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
            } else {
                router.push({
                    path: '/admin/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
            }

        } else {
            Vue.prototype.$modal.msgError("服务器异常")
        }
        // 错误处理逻辑
        return Promise.reject(error);
    });
Vue.prototype.axios = _axios

export default _axios
