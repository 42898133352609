import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/admin',
        name: 'home',
        redirect:'/statistics',
        component: () => import('../views/layout/home.vue'),
        children:[
            {
                path: '/statistics',
                name: 'statistics',
                component: () => import('../views/statistics/statistics.vue'),
                meta: { title: '统计分析' }
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('../views/user/user.vue'),
                meta: { title: '用户管理' }
            },
            {
                path: '/userDetails',
                name: 'userDetails',
                component: () => import('../views/user/userDetails.vue'),
                meta: { title: '用户详情' }
            },
            {
                path: '/store',
                name: 'store',
                component: () => import('../views/store/store.vue'),
                meta: { title: '门店管理' }
            },
            {
                path: '/videHome',
                name: 'videoHome',
                component:() => import("../views/video/videoHome.vue"),
                meta: { title: '视频管理' }
            }

        ]
    },
    {
        path: "/admin/login",
        name: "login",
        component: () => import("../views/login/login.vue"),
        meta: { title: 'pc端登录' }
    },
    {
        path: "/",
        name: "homeMobile",
        redirect:'/userMobile',
        component: () => import("../views/layout/homeMobile.vue"),
        meta: { title: '首页' },
        children:[
            {
                path: "/statisticsMobile",
                name: "statisticsMobile",
                component: () => import("../views/statistics/statisticsMobile.vue"),
                meta: { title: '首页' }
            },
            {
                path: "/userMobile",
                name: "userMobile",
                component: () => import("../views/user/userMobile.vue"),
                meta: { title: '首页' }
            },
            {
                path: "/orderMobile",
                name: "orderMobile",
                component: () => import("../views/user/orderMobile.vue"),
                meta: { title: '订单列表' }
            },
            {
                path: "/invite",
                name: "invite",
                component: () => import("../views/invite/invite.vue"),
                meta: { title: '邀请列表' }
            },
            {
                path: "/viewVideo",
                name: "viewVideo",
                component: () => import("../views/video/viewVideo.vue"),
                meta: { title: '看视频' }
            },

        ]
    },
    {
        path: "/loginMobile",
        name: "loginMobile",
        component: () => import("../views/login/loginMobile.vue"),
        meta: { title: '手机端登录' }
    },
    {
        path: "/registerMobile",
        name: "registerMobile",
        component: () => import("../views/register/registerMobile.vue"),
        meta: { title: '手机端注册' }
    },



]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router
